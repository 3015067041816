import { type FetcherWithComponents, useFetcher } from '@remix-run/react'
import { useCallback, useEffect, useMemo } from 'react'
import type { z } from 'zod'
import type { ActionResult } from '~/utils/actions.server'

type OptimisticValues<T> = {
  [K in keyof T]: T[K] | null
}

export const useFetcherForm = <Result, Schema extends z.ZodObject<any> = z.ZodObject<any>>({
  intent,
  key,
  config,
  onSuccess = () => {},
  schema
}: {
  intent: string
  key?: string
  config?: Parameters<FetcherWithComponents<unknown>['submit']>[1]
  onSuccess?: (data: Result) => void
  schema?: Schema
}) => {
  const fetcherKey = key ?? intent

  const fetcher = useFetcher<ActionResult<Result>>({ key: fetcherKey })

  const isSubmitting = fetcher.state !== 'idle'

  const submit = useCallback(
    (data?: Record<string, any>) =>
      fetcher.submit({ ...data, intent }, { method: 'POST', ...config }),
    [fetcher.submit, config, intent]
  )

  const optimisticValues = useMemo(() => {
    if (!schema || !fetcher.formData) return null

    const shape = schema.shape
    const result: Record<string, unknown> = {}

    for (const key in shape) {
      const value = fetcher.formData.get(key)
      try {
        result[key] = value !== null && value !== undefined ? shape[key].parse(value) : null
      } catch {
        console.log(`Failed to parse ${key} with value ${value}`)
        result[key] = null
      }
    }

    return result as OptimisticValues<z.infer<NonNullable<Schema>>>
  }, [schema, fetcher.formData])

  const onSuccessCallback = useCallback(onSuccess, [])

  useEffect(() => {
    if (fetcher.data?.ok) {
      onSuccessCallback(fetcher.data.data)
    }
  }, [fetcher.data, onSuccessCallback])

  return { submit, isSubmitting, optimisticValues, fetcher }
}
